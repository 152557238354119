@import "../../../styles/constants.scss";
.Message {
  font-size: $xxlarge-font-size !important;
}
.Success {
  color: $success;
}
.Error {
  color: $error;
}
.Info .Warning {
  color: $primary;
}
