@import "../../../styles/constants.scss";

.Verify {
}

.InputVerify {
  width: 100px !important;
  text-align: center;
  height: 150px;
  font-size: $xxlarge-font-size !important;
  border-radius: $radius-default !important;
}

.Phone {
  color: $error;
  font-size: $xxlarge-font-size;
}

.ContactSecretary {
  font-size: $large-font-size;
  color: $error;
  font-weight: bold;
  overflow: overlay;
}
