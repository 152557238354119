// change colors into the webpack congif too
$primary: #1b4f63;
$accent: #bee8f5;
$accent-dark: #1d71b8;
$white: #fff;
$black: #4a4a48;
$green: #9fd25f;
$sand: #f2f2f2;
$error: #ee7564;
$warning: $accent;
$success: $green;
$grey: grey;
$width: 80%;
$transition-time: 0.3s;
$radius-default: 10px;
$default-font-size: 25px;
$medium-font-size: 30px;
$large-font-size: 35px;
$xlarge-font-size: 52px;
$xxlarge-font-size: 60px;
$xxxlarge-font-size: 100px;

$default-line-height: 27px;
$medium-line-height: 32px;
$large-line-height: 37px;
$xlarge-line-height: 60px;
$xxlarge-line-height: 67px;
$xxxlarge-line-height: 105px;

$footer-height: 100px;
$header-height: 100px;
$input-height: 75px;
