@import "../../../styles/constants.scss";

.Nir {
}

.VitalCard {
  background-image: url("/assets/images/vital_card.svg");
  margin: 20px 25% 30px 25%;
  max-width: 600px;
  background-repeat: round no-repeat;
  height: 390px;
  transition: all $transition-time cubic-bezier(1, 0, 0.6, 1);
}

.Half {
  margin-bottom: 0;
  background-position-y: bottom;
  height: 150px;
}

.nirInput {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;

  border-radius: 10px !important;
  border-width: 2px !important;
  box-shadow: none !important;
  min-width: 525px !important;
}
