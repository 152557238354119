.NirInput {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  width: 100%;
  outline:none;
  // border-radius: 10px !important;
  border-width: 2px !important;
  box-shadow: none !important;
  min-width: 525px !important;
  height: 70px !important;
  font-size: 30px !important;
  margin-left: 10px !important;
  padding-left: 10px;
  background: #f2f2f2 !important;
  border: 1px solid #d9d9d9 !important;
}