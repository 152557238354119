@import "../../../styles/constants.scss";

.LongText {
  font-size: 36px;
  line-height: 38px;
  padding: 0 20px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.MediumText {
  font-size: 40px;
  line-height: 42px;
  padding: 0 20px;
  text-align: center;
}
.SmallText {
  font-size: 52px;
  line-height: 61px;
  padding: 0 20px;
  text-align: center;
}

.FatButton {
  height: 250px;
  width: 330px;
  position: relative;
  background: $sand;
  display: flex;
  border-radius: 30px;
  font-weight: bold;
  color: $primary;
  flex-direction: column;
  cursor: pointer;
  border: 2px solid $sand;
  justify-content: center;
  align-items: center;
  word-break: break-word;
}

.FatButton.Pictured * {
  flex-grow: 1;
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: $default-font-size;
  flex-direction: column;
  justify-content: space-evenly;
  line-height: 30px;
  @supports (-webkit-line-clamp: 2) {
    white-space: initial;
    text-overflow: ellipsis;
  }

  flex: 1;
}

.FatButton.Big {
  height: 335px;
  width: 409px;
}


.FatButton.Pictured span {
  max-height: 80px;
}

.smallSize {
  font-size: $large-font-size;
}
.inline {
  display: inline;
}
.medSize {
  font-size: $large-font-size;
}
.FatButton img {
  width: 100px;
}

.FatButton.Primary {
  background-color: $primary;
  border-color: $primary;
  color: $white;
}

.FatButton.Accent {
  background-color: $accent;
  border-color: $accent;
}

.FatButton.Clicked {
  border-color: $primary;
}

.FatButton.Clicked::after {
  content: "";
  position: absolute;
  top: -20px;
  right: -20px;
  width: 50px;
  height: 50px;
  background: url("/assets/icons/checked_circle-green.svg") round;
  z-index: 1;
}

.Doctor_speciality {
  font-size: 30px;
  font-style: italic;
  max-height: 80px;
  font-weight: normal;
}