@import "../../../../styles/constants.scss";

.DateInput {
    font-size: $xxlarge-font-size !important;
    text-align: center;
    padding-bottom: 0px!important;
    padding-top: 0px !important;
    padding-left: 20px !important;
    color: $accent-dark;
    border-bottom: 3px solid $primary;
}

.Slasher {
    font-size: $xxlarge-font-size!important;
    color: $primary;
}

.OptionBold {
    :global(span) {
        color: #7A7A7A;
        background-color: #f5f5f5;
    }
    :global(span:last-child) {
        padding-left: 10px!important;
    }
}