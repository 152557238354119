@import "../components/hocs/Hocs.module.scss";
@import "../components/hocs/Keyboard.module.scss";
@import "../styles/constants.scss";
@import "react-phone-number-input/style.css";
@import "react-simple-keyboard/build/css/index.css";

body {
  color: $black;
}
.card-contenant {
  margin: auto;
  width: 90vw;
  max-width: 1000px;
}
.modal-content {
  margin: auto;
  width: 90vw; 
}
.modal-info-content {
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-right: 100px;
}

.card-contenant.max-large-card {
  width: 90vw;
  max-width: none;
}
.card-contenant.large-card {
  max-width: 1200px;
}
.font-size-large {
  font-size: $large-font-size;
}
.information-card,
.error-card {
  background: $sand;
  color: $black;
  padding: 15px;
  border-radius: $radius-default;
  font-size: $default-font-size;
}

.error-card {
  color: #ee7564;
  border: 1px solid #ee7564;
  background: #ee756429;
}

.img-card {
  padding: $default-font-size;
}

.title-card,
.subtitle-card {
  font-size: $xxlarge-font-size;
  color: $black;
  line-height: 85px;
  overflow: hidden;
  width: 100%;
  text-align: center;
  margin: 0;
  font-weight: bold;
  margin-bottom: 70px;
}

.long-title-card {
  font-size: $xlarge-font-size;
  color: $black;
  overflow: hidden;
  width: 100%;
  text-align: center;
  margin: 0;
  font-weight: bold;
  top: 16px;
}

.subtitle-card {
  font-size: $xlarge-font-size;
}

.wrapped-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 70px;
}
.wrapped-buttons.vertical {
  flex-direction: column;
}
.wrapped-buttons.uniq {
  justify-content: flex-end;
}

.gender-wrapped-buttons {
  display: flex;
  align-items: center;
  margin-top: 70px;
  gap: 15px;
  justify-content: space-evenly;
}

.gender-wrapped-buttons img {
  margin-top: 20px;
  width: 150px;
}

// Only used for Transition Text
.large-margin-bottom {
  margin-bottom: $large-font-size;
}

.form-item-horizontal {
  flex-direction: row;
}

.text-transition.align-center div {
  text-align: center;
  width: 100%;
}

.ant-table table { font-size: 20px; }

// TODO do not activate this for birthLocation carousel
.ant-carousel .slick-slide {
  text-align: center;
  padding: 20px;
  width: max-content;
  line-height: 160px;
  overflow: hidden;
}

.birth-location-carousel-container .ant-carousel .slick-slide {
  text-align: center;
  line-height: 0px;
  overflow: hidden;
}

.previous-link {
  position: absolute;
  top: 0;
  left: 25px;
  z-index: 1;
  font-size: $default-font-size;
  color: $error;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: bold;
  transform: translateY(60%);
}

.previous-link svg {
  margin-right: 5px;
}

.error-color {
  color: $error;
}

.align-center {
  text-align: center;
}

.anamnese-subtitle {
  text-align: center;
  color: $black;
  font-size: $xlarge-font-size;
  margin-top: -50px;
}

.space-around {
  display: flex;
  justify-content: space-around;
}

.password-reasons {
  margin-top: 10px;
  background: transparent;
  border: none;
  color: black;
  display: flex;
  justify-content: space-between;
  padding: 0;
  font-size: 24px;
  font-weight: 600;
}

.password-reasons li.active {
  color: inherit;
}

.password-reasons li i {
  margin-left: 5px;
}

.password-reasons li.active i {
  display: none;
}

.password-reasons-invalid {
  color: $black;
}

.password-reasons-valid {
  color: $green;
}
