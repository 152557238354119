@import "../../../styles/constants.scss";

.VerifyModal {
}

.FooterModal {
  width: 100%;
  padding: $large-font-size;
  line-height: 41px;
  text-align: center;
}

.previous {
  font-size: $default-font-size;
  color: $error;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: bold;
}

.Phone {
  color: $error;
}

.ContactSecretary {
  font-size: $large-font-size;
  color: $error;
  font-weight: bold;
}
