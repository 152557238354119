@import "../../styles/constants.scss";

.Finale {
  text-align: center;
}

.secondeContainer {
  color: $error;
}

.Img {
  margin-bottom: 50px;
}

.WarningMessage {
  font-size: $large-font-size;
  color: $error;
  font-weight: bold;
  margin: 0 200px;
}