.CurrentConsultationsButton {
    position: relative;
    display: flex;
    width: 400px;
    height: 335px;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border-radius: 20px;
    background: #F2F2F2;
    gap:10;
    line-height: 3rem;
}

.appointmentContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
}
  
.appointmentDoctor {
    color: #1B4F63;
    width: 264px;
    height: 94px;
    text-align: center;
    font-variant-numeric: oldstyle-nums proportional-nums;
    font-family: Raleway;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    padding-top: 10px;
}
  
.appointmentDatetime {
    color: #1B4F63;
    text-align: center;
    font-variant-numeric: oldstyle-nums proportional-nums;
    font-family: Raleway;
    font-size: 30px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}
  
.appointmentPatient {
    font-size: 28px;
}
  
.editIcon {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 40px;
    cursor: pointer;
    padding: 10px
}
  
.editIcon:hover {
    border: 2px solid #1B4F63;
}
  
.newAppointmentButton {
    position: relative;
    display: flex;
    width: 400px;
    height: 335px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background: #F2F2F2;
    gap:40px;
    cursor: pointer;
}
  
.newAppointmentIcon{
}
  
.newAppointment{
    display: flex;
    width: 264px;
    flex-direction: column;
    justify-content: center;
    color: #1B4F63;
    text-align: center;
    font-family: Raleway;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
  }