@import "../../../../styles/constants.scss";

.InputVerify {
  width: 100px !important;
  text-align: center;
  height: 150px;
  font-size: $xxlarge-font-size !important;
  border-radius: $radius-default !important;
  background-color: $sand !important;
}

.Input {
  text-align: center;
  border: none !important;
  font-size: 150px !important;
  height: 160px !important;
  color: $primary!important;
  box-shadow:none!important;
  margin-left: -400px !important;
  outline: none !important;
}