@import "../../../styles/constants.scss";
.Previous {
  position: absolute;
  top: 26px;
  left: 0px;
  padding: 10px 10px 10px 35px;
  z-index: 1;
  font-size: $medium-font-size;
  font-weight: 600;
  color: $primary;
  text-transform: uppercase;
  cursor: pointer;
  background: $accent;
}

.Previous svg {
  margin-right: 5px;
}
