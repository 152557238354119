@import "../../../styles/constants.scss";

.Title {
  font-size: $xlarge-font-size;
  color: $primary;
  line-height: $xlarge-line-height;
  overflow: hidden;
  width: 100%;
  margin: 0;
  margin-bottom: 50px;
  font-weight: 700;
}

.Information {
  color: $primary;
  font-size: $medium-font-size;
  margin: 0;
  margin-bottom: 0px;
}
.Subtitle {
  margin-bottom: 15px;
  font-size: $large-font-size;
}
.InformationShape {
  color: $white;
  width: fit-content;
  background: $primary;
  border-radius: 40px;
  padding: 10px 40px;
}

.Important {
  color: $error;
  font-size: $large-font-size;
  font-weight: 700;
}

.ImportantShape {
  color: $white;
  width: fit-content;
  background: $error;
  border-radius: 50px;
  padding: 10px 50px;
}

.Error {
  color: $error;
}

.Warning {
  color: $warning;
}

.Success {
  color: $success;
}
