@import "../../styles/constants.scss";

.Layout {
  background-color: $white !important;
  height: 100vh;
  width: 100%;
}

.Header {
  // background-color: $accent !important;
  background-color: $white !important;
  height: $header-height;
}

.Content {
  margin: 0 50px;
  flex: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - #{$header-height} - #{$footer-height}) !important;
}

.Footer {
  background-color: $white !important;
  height: $footer-height;
}

.Card {
  width: 100%;
  border: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
}

.AvoidClick {
  position: absolute;
  left: 0;
  right: 0;
  background: transparent;
  transform: translateY(-100%);
}
/* END KEYBOARD */
