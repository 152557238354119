@import "../../styles/constants.scss";

.Home {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: center;
}

.Logo {
  width: 100%;
  margin-bottom: 50px;
}

.PartnerBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Partner {
  color: $white;
  font-size: $xxlarge-font-size;
  font-weight: 500;
  display: flex;
  place-items: center;
  img {
    margin-left: 20px;
    height: 70px;
  }
}

.Title {
  color: white;
  text-transform: uppercase;
  font-size: $xxxlarge-font-size;
  font-weight: 700;
}
.SubTitle {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  color: white;
  background: #3d2c7a;
  text-align: center;
  padding: 30px;
  font-weight: 700;
  margin: 0;
  font-size: $xxxlarge-font-size;
}
