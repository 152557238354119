@import "../../../styles/constants.scss";

.Modal {
  max-height: 800px;
}
.FlexRowWrapper {
  display: flex;
  flex-direction: column;
  place-content: space-around;
  align-items: center;
}

.ModalInfoWrapper {
  display: flex;
  flex-direction: row;
  place-content: space-around;
  align-items: center;
  padding-left: 75px;
}

.ImgModal {
  height: 176.5px;
  margin-right: 40px;
  margin-left: 96px;
}

.ContentModal {
  font-size: 33px;
}

.consentModalTitle {
  font-size: 72px;
  font-weight: bold;
  margin-left: 30.5px;
  padding-bottom: 20px;
}

.consentModalWrappedButtons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin-top: 55px;
  margin-bottom: 65px;
}

.consentModalWrappedButtons > button {
  height: 113px !important;
  width: 465px;
  border-radius: 100px;
}

.consentModalBody {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.leaveLinkConsent {
  font-size: 33px;
  color: #00abe9;
  font-weight: normal;
}

.redCrossModal {
  height: 32px;
  width: 32px;
  margin-right: 16px;
  margin-top: 16px;
}

.acceptNoEmail {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 65px;
}

.acceptInfoModal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 40px;
}

.noEmailModalBody > img {
  margin-left: 20px;
  background-color: blue;
}

.footerNoEmail {
  font-size: $large-font-size;
  text-align: left;
}

.footerNoEmailInput {
  height: 29px;
  width: 29px;
  margin-right: 10px;
}

.noEmailSpan {
  font-size: $default-font-size;
}

.noEmailLabel {
  margin-right: 12px;
}

.title__block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title__icon{
  width : 160px;
  margin-bottom: 2rem;
}

.title__icon img{
    width : 100%;
}

.title__text{
  max-width: 1000px;
  color: #211452;
  text-align: center;
  font-size: 38px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 30px;
}