@import "../../styles/constants.scss";

/* KEYBOARD */
.keyboard-button {
  width: 41px !important;
  font-weight: 600 !important;
  box-shadow: none !important;
  font-size: 38px;
  border: 1px solid $primary !important;
  color: $primary;
  border-radius: 10px !important;
  height: 80px !important;
}
.disabled-keyboard-button,
.accent-keyboard-button {
  background: transparent !important;
  cursor: auto !important;
  box-shadow: none !important;
}

.disabled-keyboard-button {
  border: none !important;
}

.keyboard-button:active {
  background: $sand;
}
.simple-keyboard {
  background: transparent !important;
  padding: 0;
  position: relative;
}
.AvoidClick {
  position: absolute;
  // height: 200px;
  left: 0;
  right: 0;
  background: transparent;
  transform: translateY(-100%);
}
/* END KEYBOARD */

.hg-button-bksp,
.bksp-keyboard-button {
  background: $sand !important;
  color: $accent-dark !important;
}
.space-keyboard-button {
  width: 350px !important;
}
.medium-keyboard-button {
  width: 90px !important;
  max-width: none !important;
}
.large-keyboard-button {
  width: 150px !important;
}

.KeyboardReset {
  position: absolute;
  top: 0;
  right: 0;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  font-size: $medium-font-size;
  border: 1px solid $primary;
  border-radius: 10px;
  background: $sand;
  text-align: center;
  width: 100%;
  font-size: 33px;
  z-index:1;
}
.InputContainer {
  min-height: 47vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
