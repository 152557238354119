@import "../../../styles/constants.scss";

.FormWidth {
  max-width: 500px;
}


.genderSpan {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $large-font-size !important;
}