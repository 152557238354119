.NirInput {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;

    border-width: 2px !important;
    box-shadow: none !important;
    min-width: 950px !important;
    border: 1px solid #d9d9d9 !important;
    padding-left: 25px !important;
    font-size: 35px !important;
    padding-top: 5px;
    padding-bottom: 5px;
}