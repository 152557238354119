@import "../../../styles/constants.scss";

.Buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 70px;
}
.Center {
  justify-content: space-evenly;
}

.Card {
  margin: auto;

}
