@import "../../../../styles/constants.scss";

.Button {
  border-radius: 10px;
  font-weight: 700;
  font-size: $large-font-size;
  padding: 8px;
}

.Button.Primary {
  background: $primary;
}
.Button.Accent {
  background: $accent;
}
.Button.Error {
  background: $error;
}
