@import "antd/dist/antd.css";
@import "../src/styles/constants.scss";

@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 200 800;
  src: url("assets/fonts/Raleway/Raleway-VariableFont_wght.ttf")
    format("truetype");
}

body {
  margin: 0;
  font-family: "Raleway";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-layout-header {
  height: 100px;
  line-height: 100px;
}

.ant-steps-icon .ant-steps-icon-dot {
  background: #1b4f63 !important;
}

.ant-notification-notice-close {
  top: -10px;
}

.ant-notification-notice-close-icon svg {
  width: 30px;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #1b4f63 !important;
}

// LARGE INNPUT / RADIO / DATEPICKER / BUTTON
.ant-btn-primary, .ant-btn-primary:focus, .ant-btn-primary:hover  {
  font-size: 38px;
  font-weight: medium;
  height: 100px;
  // do not put important
  border: 2px solid;
  background: #1b4f63;
  box-shadow: none !important;
  border-radius: 5px;
}

.ant-btn-default:hover, .ant-btn-default:focus {
  background: #fff !important;
  color: black;
  border: 1px solid transparent;
  border-color: #d9d9d9;
}

.ant-select-selector {
  padding: 0 !important;
  height: 100% !important;
  border: none !important;
  border-radius: 0 !important;
  background: transparent !important;
}
.ant-picker-large .ant-picker-input > input,
.ant-select-lg,
.ant-select,
.ant-input,
.ant-input-lg {
  font-size: 52px!important;
}
.ant-input-suffix {
  transform: scale(2);
}
input#phone.ant-input {
  font-size: 35px;
}
.ant-modal-header,
.ant-modal-content {
  border-radius: 30px;
  max-height: 920px;
}

.ant-modal-header,
.ant-modal-footer {
  border: none;
  min-height: 83px;
}
.ant-form-item-extra {
  font-size: 35px;
}
.ant-modal-close-x {
  width: 256px;
  line-height: initial;
}
.ant-checkbox {
  transform: scale(3);
  margin-right: 35px;
}
.ant-modal-close {
  left: 0;
  right: initial;
}
input:read-only {
  pointer-events: none;
}
.ant-input-affix-wrapper.ant-input-affix-wrapper-lg,
.ant-picker-large .ant-picker-input > input,
.ant-input-lg,
.ant-select-lg,
.ant-picker.ant-picker-large,
span.ant-input-password.ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
  background: #f2f2f2;
}

.ant-form-large .ant-form-item-label > label {
  font-size: 35px;
  font-weight: bold;
  height: 100%;
  color: #4a4a48;
}

.ant-form-large .ant-checkbox-wrapper {
  font-size: 36px;
  display: flex;
  align-items: center;
  margin: 0 30px;
}
.ant-select-item {
  font-size: 30px;
}

.ant-select-item,
.ant-select-lg .ant-select-selector {
  // padding: 15px 11px !important;
  height: 71px !important;
}

.ant-form-item-explain {
  font-size: 25px;
}

.ant-radio-group-large .ant-radio-button-wrapper {
  height: 80px;
  font-size: 45px;
  line-height: 80px;
  background: #f2f2f2;
}

.ant-btn-round.ant-btn-lg {
  border-radius: 65px;
}

.ant-btn-lg {
  font-size: 55px !important;
  height: max-content !important;
  font-weight: bold;
}

.ant-btn-sm {
  font-size: 25px !important;
}

.ant-card {
  border-radius: 20px;
  background: transparent !important;
  position: unset;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: white!important;
  background: #1b4f63 !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: white;
}

.inactivity-modal .ant-modal-confirm-title {
  font-size: 3em;
}

.inactivity-modal .ant-modal-confirm-content {
  font-size: 3em;
}

.ant-btn-dangerous.ant-btn-primary {
  background: #ee7564;
  border-color: #ee7564;
}

.checkNoEmail {
  margin-left: 20px;
}

.ant-btn-sm {
  font-size: 25px;
  height: 66px!important;
  background: #f2f2f2;
  text-transform: uppercase;
}
.ant-form-item-label > label{
  font-size: 27px;
  font-weight: 600;
}


// INPUTS
input,
.ant-input,
.ant-select,
.ant-select-selector,
.ant-radio-button-wrapper,
.ant-input-affix-wrapper {
  height: $input-height;
  border-color: $grey;
  font-size: $xlarge-font-size;
  line-height: $input-height;
  // new
  background: transparent;
  border: none;
  border-bottom: 2px solid $primary;
  border-color: $primary !important;
  box-shadow: none;
  padding-left: 20px;
  color: $primary;
  [type="search"]  {
    border-bottom: none;
  }
}
input:read-only {
  pointer-events: none;
}
.ant-select-selector {
  height: calc(#{$input-height} - 2px) !important;
  border: none !important;
  border-color: $primary !important;
}
.ant-select {
  padding-left: 0;
}
.ant-select-item-option {
  font-size: $xlarge-font-size;
  line-height: $xxlarge-font-size;
  color: $primary;
  padding: 20px 0;
  text-align: center;
}
.ant-btn-primary.disable-like-able {
  text-transform: uppercase;
}
.ant-btn-primary.disable-like-able[disabled],
.disable-like-able[disabled]:active {
  background: $accent;
  color: white;
  border-color: $accent;
  text-transform: uppercase;
}
.ant-input:focus,
.ant-input-focused {
  box-shadow: none !important;
}
.ant-input-affix-wrapper > input.ant-input:focus {
  box-shadow: none !important;
}
.ant-select-selection-item {
  line-height: $input-height !important;
}

.ant-select.ant-select-in-form-item {
  width: 30% !important;
}