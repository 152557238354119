@import "../../styles/constants.scss";

.OrderStep {
  text-align: center;
}

.WrappedReplies {
  display: flex;
  width: 100%;
  justify-content: space-around;
  user-select: none;
  height: 100%;
}

.ButtonSpacer {
  padding: 10px;
  gap: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1350px;
}

.Boxes {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: left;
  align-items: flex-start;
}
.Title {
  font-size: $xxlarge-font-size;
  color: $black;
  font-weight: bold;
}

.AlignRight {
  text-align: right;
}
.CheckboxColumn {
  text-align: center;
}

.AlignMiddle {
  place-self: center;
}

.AlignLeft {
  text-align: left;
}

.WrappedCarousel {
  display: flex;
}

.CarouselArrow {
  color: $primary !important;
  font-size: $xxlarge-font-size;
}

.OrderContent {
  height: 100%;
  padding-bottom: 70px;
}