@import "../../styles/constants.scss";

.NotFound,
.NotAuthorized {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  background-color: $error;
}

.Logo {
  width: 200px;
}

.Title {
  color: white;
  text-transform: uppercase;
  font-size: 3em;
}

.WipLogo {
  width: 550px;
}

.Maintenance {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  background-color: white;
}

.MaintenanceTitle {
  color: $primary;
  font-weight: 700;
  font-size: $xxlarge-font-size;
  margin-top: 20px;
}

.MaintenanceSubTitle {
  font-weight: 700;
  font-size: $large-font-size;
  color: $primary;
}
